.thumbnail-grid-container {
    height: 400px;
    overflow-y: auto;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 1rem;
}

.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    width: 100%;
    min-height: 100%;
}

.thumbnail-item {
    position: relative;
    aspect-ratio: 1;
}

.thumbnail-wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    overflow: hidden;
}

.thumbnail-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    border: none;
}

.thumbnail-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background: #f8f9fa;
}

.filename {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.delete-btn {
    padding: 0;
    color: #dc3545;
}

.upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.thumbnail-preview {
    border-radius: 4px;
    overflow: hidden;
    transition: transform 0.2s;
}

.thumbnail-wrapper:hover .thumbnail-preview {
    transform: scale(1.05);
} 