.api-demo {
    padding: 20px;
}

.credentials-form {
    max-width: 480px;
    margin: 0 auto;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;
}

.templates-section,
.create-slide-section,
.slides-section {
    margin-bottom: 30px;
}

.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

textarea {
    margin-bottom: 10px;
}

.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alert {
    margin-top: 20px;
}

.slides-section .list-group-item:hover {
    background-color: rgba(0,0,0,0.02);
}

.ratio-16x9 {
    height: 400px;
}

.list-group-item.active a,
.list-group-item.active button {
    color: white !important;
}

.list-group-item.active:hover {
    background-color: var(--bs-primary) !important;
}

.image-library-section {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.image-preview {
    display: flex;
    align-items: center;
}

.image-preview img {
    object-fit: cover;
    margin-right: 1rem;
}
